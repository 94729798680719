import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';

// components
import { VideoModal } from "../../modals";
import { Countdown, ImagePlayer, Layout } from "../../common";
import { useWindowSize } from "../../../utils/useWindowSize";
import CheckInboxModal from "../../modals/CheckInboxModal/CheckInboxModal";
import SignInModal from "../../modals/SignInModal/SignInModal";


// assets
import styles from "./BeforeReleaseDate.module.scss";
//import playBtn from "../../../images/icons/play.svg";
import background from "../../../images/phoebe.png";
import mp3_outlined from "../../../images/icons/mp3-transparent.svg";
import profile_outlined from "../../../images/icons/profile-transparent.svg";
import money_outlined from "../../../images/icons/money-transparent.svg";
import spotify from "../../../images/icons/spotify_filled.svg";
import instagram from "../../../images/icons/instagram.svg";
import youtube from "../../../images/icons/youtube.svg";
import twitter from "../../../images/icons/twitter.svg";
import facebook from "../../../images/icons/facebook.svg";
import tiktok from "../../../images/icons/tiktok_filled.svg";
import apple_music from "../../../images/icons/apple-music.svg";
import soundcloud from "../../../images/icons/soundcloud.svg";

import { RegisterInvite } from "../../common/RegisterInivite/RegisterInvite";
import { checkLive } from "../../../utils/dateCheck";
import { LogoLoader } from "../../common/LogoLoader/LogoLoader";
import { getData } from "../../../api";


export const getPerkIcon = (perk) => {
  if (perk.type === 'ROYALTY'.toLowerCase()) {
    return money_outlined;
  } else if (perk.type === 'MP3_DOWNLOAD'.toLowerCase()) {
    return mp3_outlined;
  } else if (perk.type === 'NFT_IMAGE'.toLowerCase()) {
    return profile_outlined;
  } else if (perk.type === 'CUSTOM'.toLowerCase()) {
    if (perk.imageLogo === 'tickets') {
      return <></>
    } else if (perk.imageLogo === 'signed') {
      return <></>
    } else if (perk.imageLogo === 'video') {
      return <></>
    } else if (perk.imageLogo === 'content') {
      return <></>
    } else {
      return <></>
    };
  } else {
    return <></>;
  }
};

export const BeforeReleaseDate = () => {

  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [showCheckInboxModal, setShowCheckInboxModal] = useState(false);

  const [showVideo, setShowVideo] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    
    getData(process.env.REACT_APP_SONG_ID).then((res) => {

      const response = res.data

      const is_live = checkLive(response.release_at.split('.')[0]);

      response['now_epoch'] = is_live.now_epoch
      response['date_epoch'] = is_live.date_epoch
      response['release_date'] = is_live.release_date
      response['date_check'] = is_live.now_epoch > is_live.date_epoch
  
      setData(response)
  
      setLoading(false)

    })
  
  }, []);


  const title =
    data.song_name +
    " - " +
    data.artist_name +
    " on SongBits";

  const CountDown = () => (
    <div className={styles.countdown}>
      {/*data.video_url && (
        <div className={styles.play} onClick={() => setShowVideo(true)}>
          <img src={playBtn} alt="" />
        </div>
      )*/}
      <div className={styles.timer}>
        <Countdown time={data.date_epoch} />
      </div>
    </div>
  );

  return (
    <>
    {loading ? <> <LogoLoader /> </> : <>
    <Layout
      className={styles.beforeRelease}
      title={title}
      artistName={data.artist_name}
      songName={data.song_name}
      background={background}
      noLogo
    >
      {showVideo && <VideoModal setShowVideo={setShowVideo} src={data.video_url} />}
      <div className={styles.wrapper} id="coverContainer">
        <section className={styles.left_column}>
          <div className={styles.player_container}>
            <div className={styles.player_container_wrapper}>
              <ImagePlayer preview_url={data.preview_url} cover_url={data.cover_url} />
              <p className={styles.title_counter}>COUNTDOWN TO SALE</p>
            </div>
            <CountDown />
          </div>
          {!data.date_check ? <>
          <RegisterInvite customClass={'register-1'} artist_id={data.artist_id} />
          </> : <>
          <button
            id={'buy-now-1'}
            className={styles.btnPost}
            onClick={() => {
              setShowSignInModal(true)
            }}
          >
            Buy Now
          </button>
          </>}
        </section>

        <section className={styles.right_column}>
          <div className={styles.title_container}>
            <h1 className={styles.title}>{data.song_name}</h1>
            <h2 className={styles.artist_name}>{data.artist_name}</h2>
            <h3 className={styles.sub_text}>ownership release</h3>

            <div className={styles.player_container} hidden={width > 768}>
              <div className={styles.player_container_wrapper}>
              <ImagePlayer preview_url={data.preview_url} cover_url={data.cover_url} />
                <p className={styles.title_counter}>COUNTDOWN TO SALE</p>
              </div>
              <CountDown />
            </div>
          </div>

          <div className={styles.content_container}>
            <p className={styles.content_text}>
              Grab your chance to share in the success of <span>{data.artist_name}'s</span> latest
              song <span>'{data.song_name}'</span> exclusively for fans {!data.date_check ? 'releasing on' : 'released on'}
            </p>
            <p className={styles.yellow_text}>{data.release_date}</p>

            {!data.date_check ? <>
            <p className={styles.content_text}>
              Register your interest now to receive your exclusive invite to buy
              a bit of the song and receive a share of the song's streaming
              royalties for life plus the exclusive VIP benefits listed below.
            </p>
            </> : <></>}
          </div>

          {width < 768 && (
            <div className={styles.register_wrapper}>
            {!data.date_check ? <>
            <RegisterInvite customClass={'register-2'} artist_id={data.artist_id} />
            </> : <>
            <button
              id={'buy-now-2'}
              className={styles.btnPost}
              onClick={() => {
                setShowSignInModal(true)
              }}
            >
              Buy Now
            </button>
            </>}
            </div>
          )}

          <div className={styles.content_container}>
            <p className={styles.content_title}>vip benefits included</p>
            <div className={styles.benefits_container}>

              {data.perks.sort((a, b) => a.order_number - b.order_number).map((perk, key) => {
                return (
                  <div className={styles.benefit} key={key}>
                    <img src={getPerkIcon(perk)} alt="" />
                    <p>{perk.description.replaceAll('|', '')}</p>
                  </div>
                )
              })}
              
            </div>
          </div>

          <div className={styles.content_container}>
            <p className={styles.content_title}>about the artist</p>

            {data.artist_bio.split('\\n').map((block, key) => (
              <p className={styles.content_text} key={key}>
                {block}
              </p>
            ))}
            
            <div className={styles.icons_container}>
              {data.social_media.facebook && (
                <a
                  href={'https://www.facebook.com/' + data.social_media.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    alt="facebook icon"
                    width={35}
                    height={35}
                  />
                </a>
              )}
              {data.social_media.instagram && (
                <a
                  href={'https://www.instagram.com/' + data.social_media.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt="instagram icon"
                    width={35}
                    height={35}
                  />
                </a>
              )}
              {data.social_media.tiktok && (
                <a
                  href={'https://www.tiktok.com/' + data.social_media.tiktok}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={tiktok}
                    alt="tiktok icon"
                    width={35}
                    height={35}
                  />
                </a>
              )}
              {data.social_media.twitter && (
                <a
                  href={'https://www.twitter.com/' + data.social_media.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitter}
                    alt="twitter icon"
                    width={35}
                    height={35}
                  />
                </a>
              )}
            </div>
            <div className={styles.icons_container}>

            {data.music_platforms.youtube && (
                <a
                  href={data.music_platforms.youtube}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={youtube}
                    alt="youtube icon"
                    width={35}
                    height={35}
                  />
                </a>
              )}
              {data.music_platforms.spotify && (
                <a
                  href={data.music_platforms.spotify}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={spotify}
                    alt="spotify icon"
                    width={35}
                    height={35}
                  />
                </a>
              )}
              {data.music_platforms.apple && (
                <a
                  href={data.music_platforms.apple}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={apple_music}
                    alt="apple_music icon"
                    width={35}
                    height={35}
                  />
                </a>
              )}
              {data.music_platforms.soundcloud && (
                <a
                  href={data.music_platforms.soundcloud}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={soundcloud}
                    alt="soundcloud icon"
                    width={35}
                  />
                </a>
              )}
            </div>
          </div>

          <div className={styles.register_wrapper}>
          {!data.date_check ? <>
          <RegisterInvite customClass={'register-3'} artist_id={data.artist_id} />
          </> : <>
          <button
            id={'buy-now-3'}
            className={styles.btnPost}
            onClick={() => {
              setShowSignInModal(true)
            }}
          >
            Buy Now
          </button>
          </>}
          </div>
          
          { data.reviews.length > 0 ? <>
          <div className={styles.content_container}>
            <p className={styles.content_title}>what's being said</p>
            
            {data.reviews.map((review, key) => {
                return (
                  <div className={styles.citation_container} key={key}>
                    <p className={`${styles.content_text} ${styles.margin_0}`}>
                      {review.text}
                    </p>
                    <p className={styles.yellow_text}>{review.reviewer}</p>
                  </div>
                )
              })}

          </div>
          <div className={styles.register_wrapper}>
          {!data.date_check ? <>
          <RegisterInvite customClass={'register-4'} artist_id={data.artist_id} />
          </> : <>
          <button
            className={styles.btnPost}
            id={'buy-now-4'}
            onClick={() => {
              setShowSignInModal(true)
            }}
          >
            Buy Now
          </button>
          </>}
          </div>
          </> : <></>} 

        </section>
      </div>

      <CheckInboxModal
        show={showCheckInboxModal}
        onClose={() => setShowCheckInboxModal(false)}
      />
      <SignInModal
          show={showSignInModal}
          onClose={() => setShowSignInModal(false)}
          onCreateAccount={() => {
            const utm_source = searchParams.get('utm_source') || ''
            const utm_medium = searchParams.get('utm_medium') || ''
            const utm_campaign = searchParams.get('utm_campaign') || ''

            let tracking = ''

            if (utm_campaign) {
              tracking += '&utm_campaign=' + utm_campaign
            } 
            if (utm_medium) {
              tracking += '&utm_medium=' + utm_medium
            } 
            if (utm_source) {
              tracking += '&utm_source=' + utm_source
            } 

            window.location.href = process.env.REACT_APP_SITE_URL + '/create-account?artist=' + data.artist_id + tracking
          }}
          onAlreadyHaveAccount={() => {
            window.location.href = process.env.REACT_APP_SITE_URL + data.song_url
          }}
        />

    </Layout>
    </>}
    </>
  );
};
